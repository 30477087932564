<template>
    <section>
      <div class="columns is-gapless is-marginless is-multiline m1rem">
        <b-field class="column is-12 is-narrow mt1rem inputFieldWrap">
            <b-input 
              type="search"
              :placeholder="label" 
              v-model="inputValue" 
              @focus="showModel(true)" 
              style="max-width: 250px">
            </b-input>
            <b-icon
                :icon="show ? 'menu-up' : ''"
                size="is-medium"
                class="closeBtn"
                @click.native="close">
            </b-icon>
        </b-field>
        
        <div v-if="show" class="column is-12 is-narrow selectOptionsOuterWrapper">
          <div class="selectOptionsInnerWrapper">
            <div v-for="(tvalue, tkey) in value" v-bind:key="`multiselect_${tkey}`" class="column is-12 is-narrow isOption">
              <b-field v-show="filterOption(tvalue)">
                <b-checkbox 
                  v-model="tvalue.value" 
                  @input="emitChange"
                >
                  {{tvalue.label}}
                </b-checkbox>
              </b-field>
            </div>
          </div>
        </div>        
        
      </div>
    </section>
</template>

<script>

// import { DialogProgrammatic as Dialog } from 'buefy'

export default {
    name: "multiselect",
    
    props: {
      label: {
        type: String,
        required: false,
        default: ''
      },
      isOpen: {
        type: Boolean,
        required: false,
        default: false
      },
      value: {
        type: Array,
        required: true,
        default: () => []
      },
    },
        
    data() {
      return {
        inputValue: '',
        show: false
      }
    },
    
    mounted(){
      this.show = this.isOpen
    },
    
    methods: {
      filterOption(option){
        if (!this.inputValue || this.inputValue.trim() === '') return true
        if (option.label.toLowerCase().indexOf(this.inputValue.trim().toLowerCase()) > -1) return true
        return false
      },
      showModel(show){
        this.show = show
      },
      close(){
        this.inputValue = ''
        this.show = false
      },
      emitChange(){
        this.$emit('change', true)
      }
    }
};
</script>

<style scoped>
  .selectOptionsOuterWrapper{
    position: relative;
    top:0px;
    left:0;
    width: auto;
    height: auto;    
    z-index: 999;
    /* background-color: red */
  }
  .selectOptionsInnerWrapper{
    float: left;
    position: absolute;
    margin: 0;
    margin-top: 5px;
    padding: 0;
    top:0px;
    left:0;
    width: auto;
    height: auto;    
    background-color: #FFFFFF;
  }
  
  .inputFieldWrap{
    position: relative;
  }
  
  .closeBtn{
    position: absolute;
    float: right;
    left: 170px;
    top: 6px;
    z-index: 999;
    
  }
  .closeBtn:hover{
    cursor: pointer;
    color: grey;
  }
  
  .isOption{
    display:inline;
    float:left;
    /* position: relative; */
    padding:3px;
    /* padding-left:12px; */
    margin:0;
    
    width: auto;
    height: auto;
    z-index:999;
    /* background-color: green; */
  }
    
</style>
